* {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.movingWorkItem{
  background-color: blue;
}

.clickableProfileImage {
}

.clickableProfileImage:hover {
  background: #e1e1e1;
  box-shadow: 0 0 0 2px #d0d0d0;
}

.hiddenScrollBarContainer {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hiddenScrollBarContainer::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.rhap_additional-controls {
  display: flex;
  flex: 0 !important;
  align-items: center;
}

.seciliCizelgeItem {
  background-color: #9999FF40;
}