@charset "utf-8"; /* CSS Document */

/* rating */

.rating {
    overflow: hidden;
    display: inline-block;
    font-size: 0;
    position: relative;
}
.rating-input {
    float: right;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0 0 0 -16px;
    opacity: 0;
}
.rating:hover .rating-star:hover,
.rating:hover .rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
    background-position: 0 0;
}
.rating-star,
.rating:hover .rating-star {
    position: relative;
    float: right;
    display: block;
    width: 16px;
    height: 16px;
    background: url('http://kubyshkin.ru/samples/star-rating/star.png') 0 -16px;
}


/* mixer */

.mixerContainer {
	border: 1px solid black;
	border-radius: 4px;

	display: block;
	min-width: 500px;
	width: 500px;
}

.mixerChannelListContainer {
	/* border: 1px solid black; */	
	display: block;
}

.mixerControls{
	padding-right: 30px;
}

.mixerStart {
	
}

.mixerStop {
	
}

.mixerPrev {
	
}

.mixerNext {
	
}

.mixerDuration {
  margin-top: 15px;
	font-size: smaller;
}

.mixerPlaybackRate {
  margin-top: 15px;
	font-size: smaller;
}

.mixerChannelContainer {
	display: inline-table;
	width: 50px;
	/* border-right: 1px solid black; */
	padding: 5px;
	text-align: center;
}

.mixerChannelVolume {
	width: 20px;
	height: 100px;
	display: inline-block !important;
}

.mixerTimeContainer {
	display:inline-block; 
	height: 25px;
	vertical-align: middle;
	margin-top: 4px;
	font-size: smaller;
}
.mixerChannelName {
	
}
.mixerButton {
	display: block;
	width: 25px;
	height: 25px;
	padding: 0px;
	float: left;
	margin-right: 1px;
}
/*
input[type=range].mixerRange
{
	margin-left: 5px;
    padding: 0 5px;
	border-radius: 20px;
	background-color: #242323;
}

input[type=range][orient=vertical].mixerRange
{
    writing-mode: bt-lr; 
    -webkit-appearance: slider-vertical; 
}

input::-webkit-slider-thumb.mixerRange {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border:1px solid black;

	display: block;
	
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #80e4df; 
	background: -webkit-linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%);
	background: -moz-linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%);
	background: -o-linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%);
	background: linear-gradient(top, #80e4df 0%, #75dbd6 13%, #5ec4bf 33%, #57bbb6 47%, #419d99 80%, #378f8b 100%); 
}*/

input[type=range][orient=vertical].slider {
  transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  width: 100px;
  margin: 26.55px -50px 26.55px -50px;
  height: 50px;
}

input[type=range].slider {
  -webkit-appearance: none;
  -moz-transform: rotate(270deg);
	display: inline-block !important;
	width: 100%;
  touch-action: none;
}
input[type=range].slider:focus {
  outline: none;
}

/* TRACK */
input[type=range].slider.kirmizicubuk::-webkit-slider-runnable-track {
  width: 100%;
  height: 2.9px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgba(72, 77, 77, 0.92);
  border-radius: 0px;
  border: 0.1px solid #010101;
} 

input[type=range].slider.gricubuk::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #dddddd;
  border-radius: 2px;
}

input[type=range].slider.gricubuk:focus::-webkit-slider-runnable-track,
input[type=range].slider.kirmizicubuk:focus::-webkit-slider-runnable-track {
  background: rgba(84, 90, 90, 0.92);
}

input[type=range].slider.gricubuk::-moz-range-track,
input[type=range].slider.kirmizicubuk::-moz-range-track {
  width: 100%;
  height: 2.9px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: rgba(72, 77, 77, 0.92);
  border-radius: 0px;
  border: 0.1px solid #010101;
}

input[type=range].slider.gricubuk::-ms-track,
input[type=range].slider.kirmizicubuk::-ms-track {
  width: 100%;
  height: 2.9px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* THUMB */

input[type=range].slider.kirmizicubuk::-webkit-slider-thumb {
  box-shadow: 0.3px 0.3px 0.7px #670000, 0px 0px 0.3px #810000;
  border: 0px solid #ff1e00;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background: rgba(255, 67, 95, 0.93);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6.65px;
} 
input[type=range].slider.gricubuk::-webkit-slider-thumb {
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  border: 0px solid #ff1e00;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: #868686;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.65px;
}

 input[type=range].slider::-moz-range-thumb.kirmizicubuk {
  box-shadow: 0.3px 0.3px 0.7px #670000, 0px 0px 0.3px #810000;
  border: 0px solid #ff1e00;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background: rgba(255, 67, 95, 0.93);
  cursor: pointer;
} 
input[type=range].slider::-moz-range-thumb.gricubuk {
  /* box-shadow: 0.3px 0.3px 0.7px #670000, 0px 0px 0.3px #810000; */
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  border: 0px solid #ff1e00;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  /* background: rgba(255, 67, 95, 0.93); */
  background: #868686;
  cursor: pointer;
}

 input[type=range].slider::-ms-thumb.kirmizicubuk {
  box-shadow: 0.3px 0.3px 0.7px #670000, 0px 0px 0.3px #810000;
  border: 0px solid #ff1e00;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background: rgba(255, 67, 95, 0.93);
  cursor: pointer;
  height: 2.9px;
} 
input[type=range].slider::-ms-thumb.gricubuk {
  /* box-shadow: 0.3px 0.3px 0.7px #670000, 0px 0px 0.3px #810000; */
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  border: 0px solid #ff1e00;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  /* background: rgba(255, 67, 95, 0.93); */
  background: #868686;
  cursor: pointer;
  height: 2.9px;
}

/* UPPER LOWER */

input[type=range].slider::-ms-fill-lower {
  background: rgba(60, 64, 64, 0.92);
  border: 0.1px solid #010101;
  border-radius: 0px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range].slider::-ms-fill-upper {
  background: rgba(72, 77, 77, 0.92);
  border: 0.1px solid #010101;
  border-radius: 0px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range].slider:focus::-ms-fill-lower {
  background: rgba(72, 77, 77, 0.92);
}
input[type=range].slider:focus::-ms-fill-upper {
  background: rgba(84, 90, 90, 0.92);
}

/*  SECTIONS  */
.section {
	clear: both;
	padding: 0px;
	margin: 0px;
}

/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
	margin: 1% 0 1% 1.6%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }

/*  GRID OF SIX  */
.span_6_of_6 {
	width: 100%;
}

.span_5_of_6 {
  	width: 83.06%;
}

.span_4_of_6 {
  	width: 66.13%;
}

.span_3_of_6 {
  	width: 49.2%;
}

.span_2_of_6 {
  	width: 32.26%;
}

.span_1_of_6 {
  	width: 15.33%;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 800px) {
	.col {  margin: 1% 0 1% 0%; }
	.span_1_of_6, .span_2_of_6, .span_3_of_6, .span_4_of_6, .span_5_of_6, .span_6_of_6 { width: 100%; }
}